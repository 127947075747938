import { DATE_SEGMENTS, TIME_SEGMENTS } from "./date";
import { getLocale, throwCompileErrorIfReachable } from "../../../classes/misc-util";
import { DateUtils } from "../../../classes/date.utils";
import { DateErrorType, TimeErrorType, DateRangeErrorType, TimeRangeErrorType, } from "./date.public";
export function getDateTimeSegmentPlaceholder(type) {
    switch (type) {
        case "day":
            return "TT";
        case "month":
            return "MM";
        case "year":
            return "JJJJ";
        case "minute":
            return "mm";
        case "hour":
            return "hh";
        default:
            throwCompileErrorIfReachable(type);
    }
}
export function getDateTimeSegmentMaxLength(type) {
    switch (type) {
        case "day":
            return 2;
        case "month":
            return 2;
        case "year":
            return 4;
        case "minute":
            return 2;
        case "hour":
            return 2;
        default:
            throwCompileErrorIfReachable(type);
    }
}
export function formatDate(date, formatConfig) {
    return getDateTimeFormatter(formatConfig).format(date);
}
export function formattedStringToDateString(dateString, formatConfig) {
    const splittedText = dateString.split(/[.-/]/);
    if (splittedText.length === 0 || splittedText.some((textPart) => isNaN(+textPart))) {
        return null;
    }
    const formatter = getDateTimeFormatter(formatConfig);
    const dateParts = formatter.formatToParts(new Date());
    let nextIndex = 0;
    const newDateParts = dateParts
        .filter((part) => DATE_SEGMENTS.includes(part.type) ||
        TIME_SEGMENTS.includes(part.type))
        .map((part) => {
        part.value = splittedText[nextIndex++];
        return part;
    })
        .reduce((periodParts, currentPart) => {
        const type = currentPart.type;
        periodParts[type] = currentPart.value;
        return periodParts;
    }, {
        day: "01",
        month: "01",
        year: "0000",
        hour: "00",
        minute: "00",
    });
    return `${newDateParts.year}-${newDateParts.month}-${newDateParts.day}`;
}
export function getDateTimeFormatter(formatConfig = {
    ignoreHour: true,
    ignoreMinute: true,
}) {
    return Intl.DateTimeFormat(getLocale(), {
        day: (formatConfig === null || formatConfig === void 0 ? void 0 : formatConfig.ignoreDay) ? undefined : "2-digit",
        month: (formatConfig === null || formatConfig === void 0 ? void 0 : formatConfig.ignoreMonth) ? undefined : "2-digit",
        year: (formatConfig === null || formatConfig === void 0 ? void 0 : formatConfig.ignoreYear) ? undefined : "numeric",
        hour: (formatConfig === null || formatConfig === void 0 ? void 0 : formatConfig.ignoreHour) ? undefined : "2-digit",
        minute: (formatConfig === null || formatConfig === void 0 ? void 0 : formatConfig.ignoreMinute) ? undefined : "2-digit",
    });
}
function formatDateParts(day, month, year, hour, minute, formatConfig) {
    const formattedValidDate = getDateTimeFormatter(formatConfig).formatToParts(new Date());
    return formattedValidDate
        .map((datePart) => {
        switch (datePart.type) {
            case "day":
                return DateUtils.withLeadingZero(day);
            case "month":
                return DateUtils.withLeadingZero(month);
            case "year":
                return DateUtils.withLeadingZero(year);
            case "hour":
                return DateUtils.withLeadingZero(hour);
            case "minute":
                return DateUtils.withLeadingZero(minute);
            default:
                return datePart.value;
        }
    })
        .join("");
}
export function getValueAsDate(value, mode) {
    if (value == null) {
        return null;
    }
    if (value instanceof Date) {
        return value;
    }
    if (mode === "date") {
        return new Date(value);
    }
    return new Date(`0000-01-01T${value}`);
}
/**
 * Returns a function which generates an error-message dependent on it's input and the given errorType
 * @param errorType The error the function should generate a message for.
 */
export function getErrorMessageHandle(errorType, mode = "date") {
    const _format = (date, formatConfig) => formatDate(date, formatConfig);
    if (mode === "date") {
        return getDateErrorToMessageHandle(errorType, _format);
    }
    else {
        return getTimeErrorMessageHandle(errorType, _format);
    }
}
function getDateErrorToMessageHandle(errorType, format) {
    const errorToMessageMap = {
        [DateErrorType.INVALID_DATE]: () => "Dieses Datum existiert nicht. Bitte korrigieren Sie Ihre Eingabe.",
        [DateErrorType.INCOMPLETE_DATE]: (payload) => `Bitte geben Sie das vollständige Datum im Format ${getGermanDatePlaceholder(payload.formatConfig)} ein.`,
        [DateErrorType.UNDER_MIN]: (payload) => `Bitte geben Sie ein Datum ein, das nach dem ${format(DateUtils.addDays(payload.minDate, -1), payload.formatConfig)} liegt.`,
        [DateErrorType.OVER_MAX]: (payload) => `Bitte geben Sie ein Datum ein, das vor dem ${format(DateUtils.addDays(payload.maxDate, 1), payload.formatConfig)} liegt.`,
        [DateErrorType.NO_ERROR]: () => "",
    };
    return errorToMessageMap[errorType];
}
function getTimeErrorMessageHandle(errorType, format) {
    const timeErrorToMessageMap = {
        [TimeErrorType.INVALID_TIME]: (payload) => {
            return `Die eingegebene Uhrzeit ${formatDateParts(payload.day, payload.month, payload.year, payload.hour, payload.minute, { ignoreDay: true, ignoreMonth: true, ignoreYear: true })} ${getTimeSuffix()}ist nicht valide.`;
        },
        [TimeErrorType.INCOMPLETE_TIME]: () => "Bitte geben Sie die vollständige Zeit im Format HH:MM ein.",
        [TimeErrorType.UNDER_MIN]: (payload) => `Bitte geben Sie eine Uhrzeit ein, die nach ${format(DateUtils.addMinutes(payload.minDate, -1), {
            ignoreDay: true,
            ignoreMonth: true,
            ignoreYear: true,
        })} ${getTimeSuffix()}liegt.`,
        [TimeErrorType.OVER_MAX]: (payload) => `Bitte geben Sie eine Uhrzeit ein, die vor ${format(DateUtils.addMinutes(payload.maxDate, 1), {
            ignoreDay: true,
            ignoreMonth: true,
            ignoreYear: true,
        })} ${getTimeSuffix()}liegt.`,
        [TimeErrorType.NO_ERROR]: () => "",
    };
    return timeErrorToMessageMap[errorType];
}
/**
 * Returns a function which generates an error-message dependent on it's input and the given errorType for date ranges
 * @param currentErrors All current errors which are within the date range.
 */
export function getErrorMessageHandleForDateRange(currentErrors) {
    const _format = (date, formatConfig) => formatDate(date, formatConfig);
    const mainErrorSource = getPrioritizedErrorTarget(currentErrors, "date");
    const errorType = currentErrors[mainErrorSource];
    // Record makes this type-safe. If a new DateError appears, the compiler will complain here.
    const errorToMessageMap = {
        [DateRangeErrorType.FROM_AFTER_TO]: () => {
            if (mainErrorSource === "range") {
                return "Das Startdatum muss vor dem Enddatum liegen. Bitte korrigieren Sie Ihre Eingabe.";
            }
            const dateName = mainErrorSource === "from" ? "Startdatum" : "Enddatum";
            return `Das ${dateName} existiert nicht. Bitte korrigieren Sie Ihre Eingabe`;
        },
        [DateRangeErrorType.NOT_IN_RANGE]: (payload) => `Bitte geben Sie ein Datum ein, das zwischen dem ${_format(payload.minDate, payload.formatConfig)} und dem ${_format(payload.maxDate, payload.formatConfig)} liegt.`,
        [DateErrorType.INVALID_DATE]: () => {
            const dateName = mainErrorSource === "from" ? "Startdatum" : "Enddatum";
            return `Das ${dateName} existiert nicht. Bitte korrigieren Sie Ihre Eingabe`;
        },
        [DateErrorType.UNDER_MIN]: (payload) => `Bitte geben Sie ein Datum ein, das nach dem ${_format(DateUtils.addDays(payload.minDate, -1), payload.formatConfig)} liegt.`,
        [DateErrorType.OVER_MAX]: (payload) => `Bitte geben Sie ein Datum ein, das vor dem ${_format(DateUtils.addDays(payload.maxDate, 1), payload.formatConfig)} liegt.`,
        [DateErrorType.INCOMPLETE_DATE]: (payload) => `Bitte geben Sie das vollständige Datum im Format ${getGermanDatePlaceholder(payload.formatConfig)} ein.`,
        [DateRangeErrorType.NO_ERROR]: () => "",
    };
    return errorToMessageMap[errorType];
}
export function getErrorMessageHandleForTimeRange(currentErrors) {
    const _format = (date, formatConfig) => {
        return formatDate(date, formatConfig);
    };
    const mainErrorSource = getPrioritizedErrorTarget(currentErrors, "time");
    // Record makes this type-safe. If a new DateError appears, the compiler will complain here.
    const errorToMessageMap = {
        [TimeRangeErrorType.FROM_AFTER_TO]: () => {
            if (mainErrorSource === "range") {
                return "Die Startzeit muss vor der Endzeit liegen. Bitte korrigieren Sie Ihre Eingabe.";
            }
            const timeName = mainErrorSource === "from" ? "Startzeit" : "Endzeit";
            return `Die ${timeName} existiert nicht. Bitte korrigieren Sie Ihre Eingabe`;
        },
        [TimeRangeErrorType.NOT_IN_RANGE]: (payload) => `Bitte geben Sie eine Uhrzeit ein, die zwischen ${_format(payload.minDate, {
            ignoreDay: true,
            ignoreMonth: true,
            ignoreYear: true,
            ignoreHour: false,
            ignoreMinute: false,
        })} ${getTimeSuffix()}und ${_format(payload.maxDate, {
            ignoreDay: true,
            ignoreMonth: true,
            ignoreYear: true,
            ignoreHour: false,
            ignoreMinute: false,
        })} ${getTimeSuffix()}liegt.`,
        [TimeErrorType.INVALID_TIME]: (payload) => {
            return `Die eingegebene Uhrzeit ${formatDateParts(payload.day, payload.month, payload.year, payload.hour, payload.minute, { ignoreDay: true, ignoreMonth: true, ignoreYear: true })} ${getTimeSuffix()}ist nicht valide.`;
        },
        [TimeErrorType.UNDER_MIN]: (payload) => `Bitte geben Sie eine Uhrzeit ein, die nach ${_format(DateUtils.addMinutes(payload.minDate, -1), {
            ignoreDay: true,
            ignoreMonth: true,
            ignoreYear: true,
        })}liegt.`,
        [TimeErrorType.OVER_MAX]: (payload) => `Bitte geben Sie eine Uhrzeit ein, die vor ${_format(DateUtils.addMinutes(payload.maxDate, 1), {
            ignoreDay: true,
            ignoreMonth: true,
            ignoreYear: true,
        })}liegt.`,
        [TimeErrorType.INCOMPLETE_TIME]: () => "Bitte geben Sie die vollständige Zeit im Format HH:MM ein.",
        [TimeRangeErrorType.NO_ERROR]: () => "",
    };
    return errorToMessageMap[currentErrors.range];
}
export function getTimeSuffix() {
    const locale = getLocale();
    return locale.match(/^(de-|de$)/) != null ? "Uhr " : "";
}
export function getPrioritizedErrorTarget(errors, mode) {
    if (mode === "date") {
        const dateErrors = errors;
        if (dateErrors.range !== DateRangeErrorType.NO_ERROR) {
            return "range";
        }
        if (dateErrors.from !== DateErrorType.NO_ERROR) {
            return "from";
        }
        if (dateErrors.to !== DateErrorType.NO_ERROR) {
            return "to";
        }
    }
    else {
        const timeErrors = errors;
        if (timeErrors.range !== TimeRangeErrorType.NO_ERROR) {
            return "range";
        }
        if (timeErrors.from !== TimeErrorType.NO_ERROR) {
            return "from";
        }
        if (timeErrors.to !== TimeErrorType.NO_ERROR) {
            return "to";
        }
    }
    return "range";
}
export function isInputControllerSequenceValid(...inputControllers) {
    return (inputControllers
        .filter((inputController) => !inputController.getDisabled())
        .reduce((acc, inputController) => acc * parseFloat(inputController.formattedValue), 1) > 0);
}
function getGermanDatePlaceholder(formatConfig) {
    const day = (formatConfig === null || formatConfig === void 0 ? void 0 : formatConfig.ignoreDay) ? "" : "TT.";
    const month = (formatConfig === null || formatConfig === void 0 ? void 0 : formatConfig.ignoreMonth) ? "" : "MM.";
    const year = (formatConfig === null || formatConfig === void 0 ? void 0 : formatConfig.ignoreYear) ? "" : "JJJJ";
    return `${day}${month}${year}`;
}
